import Modal from "react-modal";


export default function GenericModal(props) {
  const { modalIsOpen, setModalIsOpen, addStyle, addClass, keepAlive } = props;

  const afterOpenModal = e => {
    console.log(e);
    e.contentEl.blur();
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

 // Modal.setAppElement("#root");

  return (
    <Modal
      className={`generic-modal browser-frame  `}
      isOpen={modalIsOpen}
      onAfterOpen={e => {
        afterOpenModal(e);
      }}
      onRequestClose={keepAlive ? () => {} : closeModal}
      htmlOpenClassName={"ReactModal__Html--open"}

      closeTimeoutMS={300}
      style={{ zIndex: 4000, border: 0, width:"94vw", maxHeight: "98vh"}}
    >
      {!keepAlive && (
        <div
          onClick={closeModal}
          className="d-flex align-items-center round-box justify-content-center position-absolute "
        >
         
        </div>
      )}
      <div className="w-100 h-100 round-box content">{props.children}</div>
    </Modal>
  );
}
