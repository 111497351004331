import React from 'react'

const Reason = ({imageUrl, title, bodyText, isCard}) => {
  return (
    <div>
   {isCard&&  <div>
    <img className='col-3' style={{minHeight: "5rem"}} src={imageUrl}/> {title}
        </div>}
   {!isCard&&  <div>

      <div className='font-12'>
       {title}
      </div> 
        <div className='container'>
        <div className='row d-flex'>

    <div className='col my-4  col-6'>
        <img className='barrel-roll' src={imageUrl}/>
    </div>
    <div className='col my-4  col-6'>
{bodyText}
    </div>
    </div>
        </div>
        </div>}
        </div>
  )
}

export default Reason