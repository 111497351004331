import { useState } from 'react';
import './App.css';
import GenericModal from './components/GenericModal';
import Reason from './components/Reason';

function App() {
  let reasons = [
    {
      imageUrl: "https://upload.wikimedia.org/wikipedia/commons/e/e5/Cowgirl_Creamery_Point_Reyes_-_Red_Hawk_cheese.jpg",
      title: "Cheese",
      bodyText: "Do I need to explain!?",
    },
    {
      imageUrl: "https://www.simplyrecipes.com/thmb/sN5DV4PUSbqNW4lxBNjnTUvmaFk=/2000x1333/filters:no_upscale():max_bytes(150000):strip_icc()/__opt__aboutcom__coeus__resources__content_migration__simply_recipes__uploads__2013__02__Rosemary-Lamb-Chops-LEAD-2-4b70775441df47848c0b4352446bac77.jpg",
      title: "The boys got chops",
      bodyText: "Listen, I see it, you see it... We all know he's got chops! So settle down before he KARATE chops you!",
    },
    {
      imageUrl: "https://i.makeagif.com/media/11-04-2015/V-goZn.gif",
      title: "Science, Energy, Science, Energy!",
      bodyText: `Hey, do you wanna feel so energetic? Try Powerthirst. Energy drinks for people who need gratuitous amounts of energy. With all new flavors like Shockolate. Shockolate energy, it’s like adding chocolate to an electrical storm. Sound the alarm, you’re gonna be uncomfortably energetic.

      What’s that? You want strawberry? Well how about Rawberry? Made with lightning, real lightning. Sports (aaahhhhhh!). You’ll be good at them. It’s an energy drink for men. Menergy. These aren’t your dad’s puns, these are energy puns. Turbopuns.
      
      Science, energy, science, energy, electrolyes, turbolytes, powerlytes, more lights than your body has room for. You’ll be so fast, mother nature will be like, “Sloooooowwww dooowwwwnn.” And you’ll be like, “Fuck you!” and kick her in the face with your energy legs.
      
      You’ll have so much energy, energy (aaaahhhhh!) just running all the time. Power running, power lifting, power sweeping, power dating, power eating, power laughing, power spawning babies. You’ll have so many babies. 400 babies.
      
      Give Shockolate to your babies and they’ll be good at sports. Make your babies run abnormally fast. They’ll run as fast as Kenyans. People will watch them running and think they’re Kenyans. They’ll race as fast as Kenyans, in a race with actual Kenyans, and it’ll be a tie, and they’ll get deported back to Kenya.
      
      Hey, go with the sure thing. Don’t gamble on your energy (snake eyes!). Try Powerthirst, the energy that will make you (aaahhh!) sports (aaahhh!).`,
    },
    {
      imageUrl: "https://i.ytimg.com/vi/tA7UlASyS-Q/hqdefault.jpg",
      title: "Have you met Spyros!?",
      bodyText: "If you've met him you can see the fire in his kolotri... *caught caught* I mean buttho... I mean EYE! The fire in his eyes!!",
    },
    {
      imageUrl: "https://media.gq.com/photos/581799e0a6fe84375dbe8d86/16:9/w_2560%2Cc_limit/Cheese%25201.jpg",
      title: "Cheese AGAIN",
      bodyText: "You still don't get it do you... Umm... Listen... CHEESE! I guess if you still don't get it you never will...",
    },
    {
      imageUrl: "https://m.media-amazon.com/images/I/71EXYI44UBL._AC_SL1500_.jpg",
      title: "PHD...",
      bodyText: "He's got a phd... A PRETTY HUGE DIIIICK!!!!",
    },

  ]
  const [selectedReason, setSelectedReason] = useState({ imageUrl: "",  title: "",  bodyText: "",})
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const handleOpenModal= (r)=>{
    setSelectedReason({...r})
    setModalIsOpen(true)
  }
  return (
    <div className="App">
<div className='container'>
  <div className="font-12">
   6 Reasons why Spyros will be a billionnaire 
    </div>
    <div className='row d-flex'>
    {reasons.map(r=>
    <div className='col my-4  col-6'>
    <div onClick={()=>{handleOpenModal(r)}} className='col px-1 py-3 border rounded box  col-12'>
<Reason imageUrl={r.imageUrl} title={r.title} bodyText={r.bodyText} isCard={true}></Reason>
    </div>
    </div>
    )}
    </div>
</div>
<GenericModal
modalIsOpen={modalIsOpen}
setModalIsOpen={setModalIsOpen}
>
  <div style={{minWidth:"40vw", minHeight: "40vh"}} className='bg-dark mr-3'>
 
      <main className=" bg-dark" > 
        
<Reason imageUrl={selectedReason.imageUrl} title={selectedReason.title} bodyText={selectedReason.bodyText} isCard={false}></Reason>
        
      </main>
  </div>
</GenericModal>
    </div>
  );
}

export default App;
